import React from "react";
import { connect } from "react-redux";
import { getControlState } from "../reducers/control.slice";
import { ControlTypes } from "../types/StandardFilesTranslateTypes";
import { RootState } from "../store/store";
import { FileState, getFileState } from "../reducers/file.slice";
import { getLanguagesState, LanguagesState } from "../reducers/languages.slice";
import date from "date-and-time";
import { MACHINE_TRANSLATION_NAME, PDF_PAGES_ALLOWANCE, WORD_ALLOWANCE } from "../editorConstants";

interface BannerProps {
  control: ControlTypes;
  file: FileState;
  language: LanguagesState;
}

const mapStateToProps = (state: RootState): BannerProps => {
  return {
    control: getControlState(state),
    file: getFileState(state),
    language: getLanguagesState(state),
  };
};

const Banner: React.FC<BannerProps> = (props: BannerProps) => {
  const now = new Date();
  const humanTranslateDate = date.addDays(now, +1);
  return (
    <>
      {props.control.step === 7 ? null : (
        <>
          {props.control.step != 3 && (
            <div className="banner animate__slideInDown">
              <div className="container">
                <div className="row">
                  <div className="col">
                    <div>
                      <h1>
                        The fastest way to translate files
                      </h1>
                      <h4>
                        <ul>
                          <li className="banner-img-bullet">
                            Easy translation for 30+ file formats <br />
                            including PDF
                          </li>
                          <li className="banner-lower-text">Full format retention</li>
                          <li className="banner-lower-text">Three service and quality levels</li>
                        </ul>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {props.control.step == 3 && (
            <>
              <div className="border bg-light payment-container">
                <h1 className="">YOUR ORDER</h1>
                <div className="row translation-info">
                  <div className="col">
                    <p>From</p>
                    <p>To</p>
                    <p>{props.file.totalWordCount != 0 ? "Word count" : "Page count"}</p>
                    <p>Service</p>
                    <p>Delivery Date</p>
                  </div>
                  <div className="col">
                    <p>{props.language.sourceLanguage}</p>
                    <p>{props.language.targetLanguages.map((x) => x + " ")}</p>
                    <p>{props.file.totalWordCount != 0 ? props.file.totalWordCount : props.file.pdfFilesPages}</p>
                    <p>{props.file.service}</p>
                    <p>{props.file.service == "Instant" ? date.format(now, "YYYY-MM-DD") : date.format(humanTranslateDate, "YYYY-MM-DD")}</p>
                  </div>
                </div>
                <div className="row payment-bottom">
                  <div className="col">
                    <p>Total</p>
                  </div>
                  <div className="col">
                    {((props.file.totalWordCount < WORD_ALLOWANCE && props.file.totalWordCount != 0) || (props.file.pdfFilesPages < PDF_PAGES_ALLOWANCE && props.file.pdfFilesPages != 0)) && props.file.service == MACHINE_TRANSLATION_NAME ? (
                      <p>Free</p>
                    ) : (
                      <p>{props.file.translationCost + "€"}</p>
                    )}
                  </div>
                  <span>The price does not include value added tax (VAT).</span>
                  <p className="text-break">VAT shall apply to private customers residing in the European Union, to EU-based companies which do not have a VAT number, and to Austrian private customers and companies.</p>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default connect(mapStateToProps)(Banner);
