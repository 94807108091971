import { TUConnector } from "../connector/TUConnector";
import { removeParamsFromUrl } from "../helpers/TokenHelper";
import { removeSessionStorageItems } from "../helpers/RedirectHelpers";
import { purchaseEvent } from "./gtagService";
import { subscriptionPrice } from "../editorConstants";
import { store } from "../store/store";
import { controlActions } from "../reducers/control.slice";

export const OpenUrl =  async (url: string) => {
  try{
    const conn = new TUConnector();
    const query = new URLSearchParams(window.location.href);
    //console.log(query)
    if (query.has("projectId") || query.has("step")) {
      const projectId = query.get("projectId");
      const step = query.get("step");
    }
    if (query.has(process.env["REACT_APP_SSO_URL"] + "/?guid")) {
      let guid = query.get(process.env["REACT_APP_SSO_URL"] + "/?guid")!;
      sessionStorage.setItem("guid", guid);
      window.location.href = "https://api.textunited.com/jsoneditor/auth/login?ReturnUrl=" + process.env.REACT_APP_SSO_URL + "?step=0" + "&checkOnly=Y";
    }
    /*Handle redirect from Stripe*/
    if (query.has(process.env["REACT_APP_SSO_URL"] + "?error")) {
      removeSessionStorageItems();
      window.history.replaceState(null, "", window.location.pathname);
      removeParamsFromUrl(["error"]);
      document.location.reload();
    }
    if (query.has("ProjectId") || query.has("CostEstimationOption")) {
      console.log(process.env["REACT_APP_SSO_URL"]! + "/?ProjectId");
      const projectUrl = process.env.REACT_APP_SSO_URL + "/?ProjectId";
      console.log(projectUrl);
      const projectId = query.get(projectUrl);

      const costEstimationOption = query.get("CostEstimationOption")?.trim();
      sessionStorage.setItem("step", "4");
      sessionStorage.setItem("projectId", projectId!);
      sessionStorage.setItem("service", costEstimationOption!);
      window.history.replaceState(null, "", window.location.pathname);
      removeParamsFromUrl(["ProjectId", "CostEstimationOption"]);
      return;
    }
    if (query.has(process.env["REACT_APP_SUCCESS_URL"]!)) {
      const stripeSessionId = sessionStorage.getItem("stripeSessionId");
      if (stripeSessionId != null) {
        if(process.env["REACT_APP_ENV"] === "production"){
          purchaseEvent(stripeSessionId!, subscriptionPrice, "Subscription", "Subscription");
        }
      }
    }
    //const ssoRedirect = process.env["REACT_APP_SSO_URL"]! + "?accessToken";
    /*Handel redirects from SSO, checking if user already logged in, extracting params from url, necessary to continue editor work*/
    if (query.has(process.env["REACT_APP_SSO_URL"]! + "/?accessToken") || query.has(process.env["REACT_APP_SSO_URL"]! + "?step")) {
      let token = query.get("accessToken");
      if(token == null){
        token = query.get(process.env["REACT_APP_SSO_URL"]! + "/?accessToken");
      }

      let refresh_token = query.get("refreshToken");
      sessionStorage.setItem("token", token as string);
      sessionStorage.setItem("refresh_token", refresh_token as string);
      sessionStorage.setItem("anonymous", "false");
      store.dispatch(controlActions.setAnonymous(false));
      /*Extracting step, from which user logged in*/
      if (query.has(process.env["REACT_APP_SSO_URL"]! + "?step")) {
        const step = query.get(process.env["REACT_APP_SSO_URL"]! + "?step")!;
        const projectId = query.get("projectId")!;
        sessionStorage.setItem("step", step);
        sessionStorage.setItem("projectId", projectId);
      }
      /*Extracting languages to get cost estimation*/
      if (query.has("targetLang")) {
        const targetLang = query.get("targetLang");
        const sourceLang = query.get("sourceLang");
        sessionStorage.setItem("targetLang", targetLang!);
        sessionStorage.setItem("sourceLang", sourceLang!);
      }
      /*Extracting service and cost estimation state(true/false checks if service user has selected had the price list) which user was selected before logged in with sso */
      if (query.has("service")) {
        const service = query.get("service")?.trim();
        const words = query.get("words")?.trim();
        const isValid = query.get("isValid")?.trim();
        const pdfPages = query.get("pages")?.trim();
        const cost = query.get("cost")?.trim();
        sessionStorage.setItem("words", words!);
        sessionStorage.setItem("service", service!);
        sessionStorage.setItem("isValid", isValid!);
        sessionStorage.setItem("pdfPages", pdfPages!);
        sessionStorage.setItem("cost", cost!);
      }
      removeParamsFromUrl(["accessToken", "refreshToken", "step", "projectId", "isValid", "words", "service", "targetLang", "sourceLang", "pages", "cost", "success", "tuft"]);

      return;
    }
    //console.log("SSO URL:" +  query.has(process.env["REACT_APP_SSO_URL"]! + "?anonymous"));
    //console.log("Undefind", sessionStorage.getItem("token") === undefined);
    //console.log("nulls", sessionStorage.getItem("token") === null);
    //console.log("false", sessionStorage.getItem("token") === "false");

    if (query.has(process.env["REACT_APP_SSO_URL"]! + "/?anonymous")) {
      if(sessionStorage.getItem("anonymous") === undefined ||
          sessionStorage.getItem("anonymous") === null ||
          sessionStorage.getItem("anonymous") === "false")
          store.dispatch(controlActions.setAnonymous(false));
      {
        conn.getAnonymousToken().then((response) => {
          sessionStorage.setItem("refresh_token", response.data.refreshToken);
          sessionStorage.setItem("token", response.data.accessToken);
          sessionStorage.setItem("anonymous", "true");
          store.dispatch(controlActions.setAnonymous(true));
        });
        const newUrl = `${window.location.origin}${window.location.pathname}`;
        window.history.replaceState({}, '', newUrl);
      }
      return;
    }
    if(sessionStorage.getItem("token") && window.location.search === ""){
      if(sessionStorage.getItem("anonymous") === "false"){
          store.dispatch(controlActions.setAnonymous(false));
      }
      else{
        store.dispatch(controlActions.setAnonymous(true));
      }
      return
    }
    if(sessionStorage.getItem("token") && window.location.search.includes("?subscription")){return}
    // if (!sessionStorage.getItem("token")) {
    //   await conn.getAnonymousToken().then((response) => {
    //     sessionStorage.setItem("refresh_token", response.data.refreshToken);
    //     sessionStorage.setItem("token", response.data.accessToken);
    //     sessionStorage.setItem("anonymous", "true");
    //     store.dispatch(controlActions.setAnonymous(true));
    //   });
    // }

    //&& window.location.search === ""
    if (!sessionStorage.getItem("token") && (window.location.search.includes("?lang"))) {
      const lang = query.get(process.env["REACT_APP_SSO_URL"]! + "?lang");
      sessionStorage.setItem("lang", lang!);
      //window.location.href = url;
    }

    if (window.location.search.includes("utm_campaign")) {
      conn.getAnonymousToken().then((response) => {
        sessionStorage.setItem("refresh_token", response.data.refreshToken);
        sessionStorage.setItem("token", response.data.accessToken);
        sessionStorage.setItem("anonymous", "true");
        store.dispatch(controlActions.setAnonymous(true));
        // window.location.href = document.location.href!;
        const params = Array.from(query.entries()).map(([key]) => `${key}`);
        params.push("utm_term");
        removeParamsFromUrl(params);
        return;
      });
    }
    else{
      //document.getElementById("redirect")!.setAttribute("src", url);
      store.dispatch(controlActions.setSSOLoading(true));
      // const url = new URL(window.location.href);
      // const urlWithoutParams = url.origin + url.pathname;
      // window.history.replaceState({}, '', urlWithoutParams);
      window.location.href = url;
      

    }
  }catch (e){
    console.log(e);
  }

  //if (sessionStorage.getItem("token") && window.location.search === "") return;
};
