/**
 Properties used in controls reducer
 */
import { ClientSubscription, Country, Project, UserLocation, UserType } from "../connector/models/apiModels";

export interface ControlTypes {
  step: number;
  animation: string;
  returnDialogIsOpen: boolean;
  orderStep: number;
  isHasAccount: boolean;
  location: UserLocation;
  carouselArrow: boolean;
  loadingMessage: string;
  showError: boolean;
  errorMessage: string;
  showModalError: boolean;
  redirectFromStripe: boolean;
  showWordAllowanceWarning: boolean;
  showSameFilesNameModal: boolean;
  showPdfMixingWarning: boolean;
  showFileLoadingPopUp: boolean;
  showFileSizePopUp: boolean;
  popUpTitle: string;
  showSameLanguagesPopUp: boolean;
  stepperSteps: string[];
  taxNumberAlert: boolean;
  userInfoAlert: boolean;
  fieldValidation: FieldValidation;
  isSSOLoading: boolean;
  showSubscriptionCancelModal: boolean;
  showFileDownloadingModal: boolean;
  isAnonymous: boolean;
}

export interface UploadUserFile {
  projectId: string;
  isLoading: boolean;
  show: boolean;
  typeError: boolean;
  fileCountError: boolean;
  wordsCountError: boolean;
  fileSizeError: boolean;
  success: boolean;
  translatedFile: Download;
  files: UploadedFile[];
}

export interface Download {
  base64File: string;
}

export interface TransUnit {
  id: number;
  segmentText: string;
  description: string;
  afterCharacter: string;
  beforeText: string;
  filterSpecificNotes?: any;
}

/**
 * Properties used in language reducer
 */
export interface LanguagesTypes {
  id: number;
  systemName: string;
  nativeName: string;
  langCode: string;
  descriptiveName: string;
  isRtl: boolean;
  countries: Country[];
}

/**
 * Detected language action type
 */
export type DetectedLanguage = {
  langId: number;
  langCode: string;

};

/**
 * Model that represents uploaded file props
 */
export interface UploadedFile {
  projectId: string;
  fileId: string;
  fileName: string;
  fileExtension: string;
  name: string;
  extension: string;
  wordcount: number;
  pdfType: number;
  transUnits: TransUnit[];
}

/**
 * Type that shows up in FilesList component
 */
export interface FilesList {
  fileName: string;
  isLoading: boolean;
  id: string;
  error: boolean;
  warning: boolean;
  errorMsg: ErrorMessage;
  fileId?: string;
  progress?: number | undefined;
}

export interface ErrorMessage {
  content: string | null;
  color: string | null;
  fileName: string | null;
  errorType: ErrorType;
}

export enum ErrorType {
  noError,
  sourceLanguageDifference,
  processingError,
}

/** User registration models **/

/*User address data object */

export interface Address {
  address1: string;
  city: string;
  countryId: number;
  zipCode: string;
  countryName?: string;
}

export interface Company {
  name: string;
  ownerId?: number;
  taxNumber?: string;
  email?: string;
  address?: Address;
}

export interface User {
  firstName: string;
  lastName: string;
  address: Address;
  company: Company;
  email: string;
  type: UserType;
}

/*This model used only to group reducer props into one object*/
export interface UserRegisterInfo {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export interface FileProgressType {
  fileName: string;
  progress: number;
}

export interface SubscriptionType {
  isActive: boolean;
  dataLoaded: boolean;
  subData: ClientSubscription;
}

export interface ProjectType extends Project {}

export interface TaxTypeUpdate {
  countryId: number;
  taxNumber: string;
  taxTypeCode: string;
}

export interface FieldValidation {
  firstName: boolean;
  lastName: boolean;
  email: boolean;
  companyName: boolean;
  city: boolean;
  street: boolean;
  zipCode: boolean;
  taxNumber: boolean;
}
