import React from "react";
import Modal from "@mui/material/Modal";
import { Box, Button, CircularProgress, Fade } from "@mui/material";
import { AppDispatch, RootState } from "../../store/store";
import { connect } from "react-redux";
import { FileState, getFileState } from "../../reducers/file.slice";
import { ControlTypes } from "../../types/StandardFilesTranslateTypes";
import { controlActions, getControlState } from "../../reducers/control.slice";

interface FileIsDownloadingModalProps {
  file: FileState;
  controls: ControlTypes;
  onCloseClick(show: boolean): void;
}

const mapStateToProps = (state: RootState) => {
  return {
    file: getFileState(state),
    controls: getControlState(state),
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    onCloseClick: (show: boolean) => dispatch(controlActions.showFileDownloadingModal(show)),
  };
};

const FileIsDownloadingModal: React.FC<FileIsDownloadingModalProps> = (props: FileIsDownloadingModalProps) => {
  
  return (
    <Modal open={props.controls.showFileDownloadingModal}>
      <Fade in={props.controls.showFileDownloadingModal}>
        <Box className="word-allowance-modal">
          <div className="word-allowance-modal-content mx-4">
            <div className="word-allowance-modal-description">
             Downloading your file. Please wait. <br />
            </div>
            <div className="w-full flex justify-center">
             <CircularProgress />
            </div>
            
            <span className="text-sm text-center">You can find your file in the "Downloads" folder of your system</span>
            <div className="text-center">
              <Button style={{ color: "whitesmoke" }} variant={"outlined"} onClick={() => props.onCloseClick?.(false)}>
                Close
              </Button>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FileIsDownloadingModal);
