import { TransUnit, User, Address, Company as TypeCompany, TaxTypeUpdate } from "../../types/StandardFilesTranslateTypes";

/**
 * Request to validate supported files
 */

export interface ValidateFilesRequest {
  files: string[];
  tuft: boolean;
}

/**
 * Response from file validation endpoint
 */
export interface ValidateFilesResponse {
  files: File[];
}

export interface File {
  name: string;
  isValide: boolean;
  errorMessage: string;
  filterId: number;
  filterName?: any;
}

/**
 * Request model for convert endpoint
 * @property projectId -  GUID regenerated by client-side;
 * @property name - file name
 * @property base64String - files encoded converted to Base64 format
 * @property validateFilter - add extra validation by Docporcessor site, to validate img/zip files
 * @property pdfType - type of the pdf file, 1 - true, 2 - image
 *
 */
export interface ConvertFileRequest {
  projectId: string;
  name: string;
  base64String: string;
  validateFilter: boolean;
  fileSource: number;
  pdfType?: PdfType | null;
  fileId?: string | null;
}

export enum PdfType {
    AutoDetect = 0,
    True = 1,
    Image = 2,
}

/**
 * @property projectId - GUID regenerated by client-side
 * @property fileId - file id returned from ms
 * @property fileName - file name
 * @property fileExtension - file extension
 * @property wordCount - words count in document
 * @property transUnits - Array of trans units returned from ms
 * @property pdfType - type of the PDF file, Image/True
 * @property pageCount - number of upload pdf pages
 */
export interface ConvertFileResponse {
  projectId: string;
  fileId: string;
  fileName: string;
  fileExtension: string;
  name: string;
  extension: string;
  wordcount: number;
  pdfType: number;
  transUnits: TransUnit[];
  pageCount: number;
  isLoading?: boolean;
  progress?: number | null;
  sourceLanguageCode: string | null;
}

/**
 * @param projectId - Generated project uuid by editor site
 * @param sourceLanguageCode - Source language code
 * @param targetLanguageCodes - Array of target language codes
 */
export interface CostEstimationRequest {
  projectId: string;
  sourceLanguageCode: string;
  targetLanguageCodes: string[];
}

/**
 * @param isValid - shows if all target languages in price list, so we can show cost estimation
 * @param machineTranslationCost - cost of machine translation
 * @param machineTranslationAndReviewCost - cost of machine translation and humane post-review
 * @param humanTranslationAndRevisionCost - cost of human translation and post review
 * @param pdfPageCost - cost of MT for pdf page
 * @param pdfPageCount - pdf file pages count
 */
export interface CostEstimationResponse {
  consEstimations: ConstEstimationLanguage[];
  isValid: boolean;
  machineTranslationCost: number;
  machineTranslationAndReviewCost: number;
  humanTranslationCost: number;
  humanTranslationAndRevisionCost: number;
  pdfPageCost: number;
  pdfPageCount: number;
}

export interface ConstEstimationLanguage {
  pricelistAvailable: boolean;
  currencyId: number;
  wordTranslationPrice: number;
  wordProofreadingPrice: number;
  managementFactorPrice: number;
  translationPrice: number;
  proofreadingPrice: number;
  managementTranslationPrice: number;
  managementProofreadingPrice: number;
  segmentCount: number;
  wordCount: number;
  repetitionCount: number;
  repetitionWordCount: number;
  fuzzymatchCount: number;
  fuzzymatchWordCount: number;
  nomatchCount: number;
  nomatchWordCount: number;
  match100Count: number;
  match100WordCount: number;
  matchContextCount: number;
  matchContextWordCount: number;
  machineTranslationCost: number;
  machineTranslationAndReviewCost: number;
  humanTranslationCost: number;
  humanTranslationAndRevisionCost: number;
}

export interface Token {
  accessToken: string;
  refreshToken: string;
  tfaToken?: any;
}

/**
 * Uses to get user location and user IP
 */
export interface UserLocation {
  country_code: string;
  country_name: string;
  city: string;
  postal: string;
  latitude: number;
  longitude: number;
  IPv4: string;
  state: string;
}

/**
 * @param projectId - guid of the project generated by client side
 * @param costEstimationOption - translation options, ex. Instant(MT)
 * @param successUrl - redirect url on payment success
 * @param cancelUrl - redirect url on payment fail
 * @param title - title of the product, can be any name, but by default its Translation Option ex. Instant
 * @param type - product type, for editor its TUFT
 */
export interface PrepareStripeCheckoutRequest {
  projectId: string;
  costEstimationOption: CostEstimationOptions.MachineTranslationCost;
  successUrl: string;
  cancelUrl: string;
  title: string;
  type: string;
}

export enum CostEstimationOptions {
  MachineTranslationCost = 1,
  MachineTranslationAndReviewCost = 2,
  HumanTranslationCost = 3,
  HumanTranslationAndRevisionCost = 4,
}

/**
 * @param projectId - guid of the project generated by client side
 * @param stripeSessionId - session id returned from stripe, use to get checkout url
 */
export interface PrepareStripeCheckoutResponse {
  projectId: string;
  stripeSessionId: string;
  url: string;
}

/*
Request interface to send email with user rating to TextUnited support
 */
/**
 * @property attachments - lists of attachments
 * @property body - body of the email
 * @property  title - title of the email
 * @property emailRecipients - list of email recipients
 */
export interface MailRequest {
  attachments: MailAttachment[] | null;
  body: string;
  title: string;
  emailRecipients: string[];
  sendAlways: boolean;
}

/**
 * @property contentBase - file content converted to Base64 string
 * @property fileName - name of the attachments file
 */
interface MailAttachment {
  contentBase: string;
  fileName: string;
}

/*
Response interface from stripe checkout, we use only one parameter "url"
 */
/**
 * @param url - redirect url for payment checkout
 */
export interface StripeCheckoutResponse {
  id: string;
  object: string;
  after_expiration?: any;
  allow_promotion_codes?: any;
  amount_subtotal: number;
  amount_total: number;
  automatic_tax: AutomaticTax;
  billing_address_collection?: any;
  cancel_url: string;
  client_reference_id?: any;
  consent?: any;
  consent_collection?: any;
  created: number;
  currency: string;
  customer: string;
  customer_creation?: any;
  customer_details: CustomerDetails;
  customer_email?: any;
  expires_at: number;
  livemode: boolean;
  locale?: any;
  metadata: Metadata;
  mode: string;
  payment_intent: string;
  payment_link?: any;
  payment_method_collection: string;
  payment_method_options: PaymentMethodOptions;
  payment_method_types: string[];
  payment_status: string;
  phone_number_collection: PhoneNumberCollection;
  recovered_from?: any;
  setup_intent?: any;
  shipping?: any;
  shipping_address_collection?: any;
  shipping_options: any[];
  shipping_rate?: any;
  status: string;
  submit_type?: any;
  subscription?: any;
  success_url: string;
  total_details: TotalDetails;
  url: string;
}

export interface AutomaticTax {
  enabled: boolean;
  status?: any;
}

export interface CustomerDetails {
  address?: any;
  email: string;
  name?: any;
  phone?: any;
  tax_exempt: string;
  tax_ids?: any;
}

export interface Metadata {
  type: string;
}

export interface PaymentMethodOptions {}

export interface PhoneNumberCollection {
  enabled: boolean;
}

export interface TotalDetails {
  amount_discount: number;
  amount_shipping: number;
  amount_tax: number;
}

/*Response from Countries endpoint */
/**
 * @param id - country's id in TU db
 * @param languageCode - language code in ISO format
 * @param countryName - name of the country
 * @param taxType - Stripe tax type object
 */
export interface Country {
  id: number;
  languageCode: string;
  countryName: string;
  taxTypes: TaxType[];
}

export interface TaxType {
  stripeCode: string;
  name: string;
}

/*Check if user payment in Stripe was successfully response*/
export interface StripeBuySuccessfullyResponse {
  projectId: string;
  paid: boolean;
  amount: number;
  title: string;
}

/*MT response */
export interface MachineTranslationResponse {
  projectId: string;
  machineTranslationStatus: number;
  machineTranslationStatusName: string;
}

/*Register new user request*/
/**
 * @param user - User object with necessary information to register new user
 */
export interface RegisterUserRequest {
  user: User;
  password: string;
  registrationSource: RegistrationSource;
}

export enum RegistrationSource {
  Portal = 0,
  Tuft = 1,
}
export enum UserType {
  PersonalUser = 10
}
/* Register new user response models */

export interface AddressResponse {
  Address1: string;
  Address2: string;
  City: string;
  Province: string;
  ZipCode: string;
  CountryId: number;
  CountryName: string;
}

export interface Address2 {
  Address1: string;
  Address2: string;
  City: string;
  Province: string;
  ZipCode: string;
  CountryId: number;
  CountryName: string;
}

export interface Company {
  Id: number;
  Name: string;
  OwnerId: number;
  Address: Address2;
  TaxNumber: string;
  Phone: string;
  Website: string;
  Email: string;
  CompanyPass?: any;
  Logo?: any;
  Invoicing?: any;
  BankAccount: string;
  BankAccountOwner: string;
  BankName: string;
  ReverseCharge: boolean;
  BankSwiftCode: string;
  BusinessCertificate: string;
}

export interface Productivity {
  Translation: number;
  Proofreading: number;
  Terminology: number;
}

export interface UserResponse {
  Login: string;
  Type: number;
  State: number;
  Address: AddressResponse;
  Company: Company;
  Language: number;
  Email: string;
  Phone: string;
  Picture?: any;
  Superior?: any;
  Position: string;
  Verified: boolean;
  PublicProfile: boolean;
  Productivity: Productivity;
  LoggedIn: boolean;
  BusinessUnit?: any;
  BusinessUnitId?: any;
  Settings: number;
  CanOrderTranslation: boolean;
  Id: number;
  FirstName: string;
  LastName: string;
  Name: string;
}

export interface RegisterUserResponse {
  User: UserResponse;
  ConfirmationString?: any;
  userToken: string;
}

/**
 * Response from subscription ms,return info about users subscription
 * @property wordsAvailable - all words available to user including words packages
 */
export interface CheckUserWordsAllowanceResponse extends ClientSubscription {
  wordsAvailable: number;
  pdfPagesAvailable: number;
}

/**
 * Request from editor to portal to log in user after account registration
 * @property email - user's email
 * @property accessToken - access token, after user registration
 * @property refreshToken - refresh token, after user registration
 */
export interface LoginUserRequest {
  email: string;
  accessToken: string;
  refreshToken: string;
}

/**
 * Request to communication ms
 *@property emailRecipients - list of emails
 * @property sendAlways - indicates if send email to the same recipient
 * @property title - title of the email
 * @property body -
 */

export interface MailGeneralPurposeRequest {
  emailRecipients: string[];

  title: string;
  body: string;
}

export interface GetUserInfoResponse {
  login: string;
  firstName: string;
  lastName: string;
  email: string;
  address: Address;
  company: TypeCompany;
  type: UserType;
}

/**
 * Response from ClientSubscriptionAllowance
 */
export interface ClientSubscription {
  subscriptionName: string;
  subscriptionPlanId: number;
  companyId: number;
  validTo: Date | null;
  
  wordsAvailable: number;
  wordsAvailablePercent: number;
  wordsTotal: number;
  wordsUsed: number;
  wordsUsedPercent: number;
  
  wordsSubscriptionAvailable: number;
  wordsSubscriptionPercentAvailable: number;
  wordsSubscription: number;
  wordsSubscriptionUsed: number;
  wordsSubscriptionPercentUsed: number;

  wordsPack: number;
  wordsPackUsed: number;
  wordsPackAvailable: number;

  pdfPagesAvailable: number;
  pdfPagesAvailablePercent: number;
  pdfPagesUsed: number;
  pdfPagesUsedPercent: number;
  pdfPagesTotal: number;

  pdfPagesSubscriptionAvailable: number;
  pdfPagesSubscriptionPercentAvailable: number;
  pdfPagesSubscription: number;
  pdfPagesSubscriptionUsed: number;
  pdfPagesSubscriptionPercentUsed: number;

  pdfPagesPack: number;
  pdfPagesPackUsed: number;
  pdfPagesPackAvailable: number;
  
  updatedTimestamp: Date | null;
  paidUntil: Date | null;
  price: number;
  priceYearly: boolean;
  currency: number;
  currencyName: string;
  resetDate: Date | null;
  canceled: boolean;
}

/**
 * Response model for projetcs list endpoint
 */
export interface Project {
  projectId: string;
  companyProjectId: number | null;
  userId: number;
  companyId: number;
  anonymous: boolean;
  created: Date;
  paid: boolean;
  costEstimationOption: number | null;
  costEstimationRequest: ProjectCostEstimationRequest;
  fileSource: number;
  offerRequested: boolean;
  serviceName: string;
  servicePrice: number;
  targetLanguageCodes: string;
  sourceLanguageCode: string;
}

export interface ProjectCostEstimationRequest {
  sourceLanguageId: number;
  targetLanguageIds: number[];
  sourceLanguageCode: string;
  targetLanguageCodes: string[];
  projectId: string;
}

export interface SubscriptionBuyRequest {
  subscriptionId: number;
  successUrl: string;
  cancelUrl: string;
  priceYearly: boolean;
}

export interface SubscriptionBuyResponse {
  stripeSessionId: string;
  url: string;
}

export interface CompanyPreferencesResponse {
  preferenceProperties: PreferenceProperties;
}

export interface PreferenceProperties {
  taxType: TaxType;
  taxNumber: string;
}

export interface ContactAddress {
  line1: string;
  city: string;
  postalCode: string;
  state: string;
  country: string;
}

export interface Contact {
  type: EventType
  data: Data;
}

export interface SendGridContact {
  type: EventType,
    data: SendgridData[]
}

export interface Data {
  name: string;
  first_name: string;
  last_name: string;
  description: string;
  address: ContactAddress;
  custom_fields: CustomFields;
  email: string;
  tags: [];
}

export interface SendgridData {
  email: string;
  first_name: string,
  last_name: string,
  city: string,
  country: string,
  custom_fields: CustomFieldsSendGrid
}

export interface CustomFields {
  source_of_the_lead: string;
}
export interface CustomFieldsSendGrid {
  /**
   * @property e1_T - SendGrid custom field
   */
  e1_T: string | null;
}
export interface SetTaxNumberRequest extends TaxTypeUpdate {}

export type ProjectRoot = ProjectInfo[];

export interface ProjectInfo {
  projectId: string;
  fileName: string;
  wordcount: number;
  fileId: string;
  created: string;
  fileExtension: string;
  segments: any[];
  filePath: any;
  docProcessingError: number;
  docProcessingErrorMessage: any;
  projectFileType: number;
  fileStatusPreprocessing: number;
  pageCount: number;
}

export interface CreateProjectResponse {
  docProcessorProjectId: string;
  targetLanguages: TargetLanguage[];
}

interface TargetLanguage{
  projectId: number
}

export interface RegisterNewUserMail{
  emailRecipients: string[];
  name: string;
  compressWhileSerializing: boolean;
  attachments: string[];

}

export enum EventType {
  CreateZendeskContact = 37,
  CreateSendGridContact = 38,
}

export interface SubscribePlan {
  subscriptionId: number;
  priceYearly: boolean;
}