import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ControlTypes } from "../types/StandardFilesTranslateTypes";
import { RootState } from "../store/store";
import { UserLocation } from "../connector/models/apiModels";
import { ShowFileSizePopUp, TextFieldValidation } from "../actions/actionsTypes";
import { faL } from "@fortawesome/free-solid-svg-icons";
import { set } from "react-ga";

export const CONTROL_FEATURE_KEY = "control";

export type CreateProject = {
  projectId: string;
  costEstimationOption: string;
};
export type NextOrderStep = {
  step: number;
};

/*Generators*/
export const generateSetAnimationOnNextStepAction = createAction<{
  activeStep: number;
}>("control/generateSetAnimationOnNextStepAction");
export const generateShowErrorAction = createAction<{
  show: boolean;
  action: string;
}>("control/generateShowErrorAction");
export const generateCheckUserEmail = createAction<{ email: string }>("control/generateCheckUserEmail");
export const generatePrepareOrderLoggedInUserAction = createAction<{
  price: string;
}>("control/generatePrepareOrderLoggedInUserAction");
export const generateCreateProjectAction = createAction<CreateProject>("control/generateCreateProjectAction");
export const generatePrepareOrderForNotLoggedInUserAction = createAction<{
  price: string;
}>("control/generatePrepareOrderForNotLoggedInUserAction");
export const generateNextOrderStepAction = createAction<NextOrderStep>("control/generateNextOrderStepAction");
export const generateUserRateAction = createAction<{ rate: number }>("control/generateUserRateAction");
export const generateDownloadMachineTranslationFileAction = createAction("control/generateDownloadMachineTranslationFileAction");
export const generateLogoutAction = createAction("control/generateLogoutAction");
export const generateLoginAction = createAction("control/generateLoginAction");
export const generateCheckRedirectAction = createAction("control/generateCheckRedirectAction");

const initState: ControlTypes = {
  animation: "",
  step: 0,
  returnDialogIsOpen: false,
  orderStep: 1,
  isHasAccount: false,
  location: {} as UserLocation,
  carouselArrow: false,
  loadingMessage: "",
  showError: false,
  errorMessage: "",
  showModalError: false,
  redirectFromStripe: false,
  showWordAllowanceWarning: false,
  showSameFilesNameModal: false,
  showPdfMixingWarning: false,
  showFileLoadingPopUp: false,
  showFileSizePopUp: false,
  popUpTitle: "",
  showSameLanguagesPopUp: false,
  stepperSteps: ["Upload files", "Choose languages", "How to translate", "Download translation or order"],
  taxNumberAlert: false,
  userInfoAlert: false,
  isSSOLoading: false,
  isAnonymous: true,
  fieldValidation: {
    firstName: false,
    companyName: false,
    city: false,
    lastName: false,
    email: false,
    street: false,
    zipCode: false,
    taxNumber: false,
  },
  showSubscriptionCancelModal: false,
  showFileDownloadingModal: false,
};

export const controlSlice = createSlice({
  name: CONTROL_FEATURE_KEY,
  initialState: initState,
  reducers: {
    setValidationTextField: (state, action: PayloadAction<TextFieldValidation>) => {
      switch (action.payload.field) {
        case "firstName":
          state.fieldValidation.firstName = action.payload.validationResult;
          break;
        case "lastName":
          state.fieldValidation.lastName = action.payload.validationResult;
          break;
        case "email":
          state.fieldValidation.email = action.payload.validationResult;
          break;
        case "companyName":
          state.fieldValidation.companyName = action.payload.validationResult;
          break;
        case "city":
          state.fieldValidation.city = action.payload.validationResult;
          break;
        case "street":
          state.fieldValidation.street = action.payload.validationResult;
          break;
        case "zipCode":
          state.fieldValidation.zipCode = action.payload.validationResult;
          break;
        case "taxNumber":
          state.fieldValidation.taxNumber = action.payload.validationResult;
          break;
      }
    },
    setUserInfoAlert: (state, action: PayloadAction<boolean>) => {
      state.userInfoAlert = action.payload;
    },
    setTaxNumberAlert: (state, action: PayloadAction<boolean>) => {
      state.taxNumberAlert = action.payload;
    },
    hideAllPopUps: (state) => {
      state.showFileLoadingPopUp = false;
      state.showFileSizePopUp = false;
      state.taxNumberAlert = false;
      state.userInfoAlert = false;
    },
    showLanguagesPopUp: (state, action: PayloadAction<ShowFileSizePopUp>) => {
      state.showSameLanguagesPopUp = action.payload.show;
      state.popUpTitle = action.payload.content!;
    },
    showFilesSizePopUp: (state, action: PayloadAction<ShowFileSizePopUp>) => {
      state.showFileSizePopUp = action.payload.show;
      state.popUpTitle = action.payload.title!;
    },
    showFileLoadingPopUp: (state, action: PayloadAction<boolean>) => {
      state.showFileLoadingPopUp = action.payload;
    },
    showPdfMixingWarningModal: (state, action: PayloadAction<boolean>) => {
      state.showPdfMixingWarning = action.payload;
    },
    setLoadingScreenMessage: (state, action: PayloadAction<string>) => {
      state.loadingMessage = action.payload;
    },
    showSameFilesNameModal: (state, action: PayloadAction<boolean>) => {
      state.showSameFilesNameModal = action.payload;
    },
    setUserLocationData: (state, action: PayloadAction<UserLocation>) => {
      state.location = action.payload;
    },
    setShowWordsAllowanceWarningModal: (state, action: PayloadAction<boolean>) => {
      state.showWordAllowanceWarning = action.payload;
    },
    setAccount: (state, action: PayloadAction<boolean>) => {
      state.isHasAccount = action.payload;
    },
    setActiveStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    setAnimationClass: (state, action: PayloadAction<string>) => {
      state.animation = action.payload;
    },
    openReturnDialog: (state, action: PayloadAction<boolean>) => {
      state.returnDialogIsOpen = action.payload;
    },
    setOrderStep: (state, action: PayloadAction<number>) => {
      state.orderStep = action.payload;
    },
    setErrorModalMsg: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
    },
    showErrorModal: (state, action: PayloadAction<boolean>) => {
      state.showModalError = action.payload;
    },
    showErrorWindow: (state, action: PayloadAction<boolean>) => {
      state.showError = action.payload;
    },
    closeErrorWindow: (state) => {
      state.showError = false;
    },
    setRedirectFromStripe: (state, action: PayloadAction<boolean>) => {
      state.redirectFromStripe = action.payload;
    },
    addStepperStep: (state, action: PayloadAction<string>) => {
      if (!state.stepperSteps.some((x) => x == action.payload)) {
        state.stepperSteps.splice(3, 0, action.payload);
      }
      //state.stepperSteps.splice(2, 0, action.payload);
    },
    setSSOLoading: (state, action: PayloadAction<boolean>) => {
      state.isSSOLoading = action.payload;
    },
    showSubscriptionCancelModal: (state, action: PayloadAction<boolean>) => {
      state.showSubscriptionCancelModal = action.payload;
    },
    showFileDownloadingModal: (state, action: PayloadAction<boolean>) => {
      state.showFileDownloadingModal = action.payload;
    },

    setAnonymous: (state, action: PayloadAction<boolean>) => {
      state.isAnonymous = action.payload;
    },
  },
});


/*
 * Export reducer for store configuration.
 */
export const controlReducer = controlSlice.reducer;

export const controlActions = controlSlice.actions;

export const getControlState = (rootState: RootState): ControlTypes => rootState[CONTROL_FEATURE_KEY];
