import React, { useEffect, useState } from "react";
import { AppDispatch, RootState } from "../store/store";
import { connect } from "react-redux";
import Select, { SingleValue } from "react-select";

import {
  generateGetCostEstimation,
  generatorFetchLanguages,
  languagesActions,
  LanguagesEntity,
  LanguagesState,
  selectAllLanguages
} from "../reducers/languages.slice";
import { ControlTypes, LanguagesTypes } from "../types/StandardFilesTranslateTypes";
import { controlActions, generateSetAnimationOnNextStepAction, getControlState } from "../reducers/control.slice";
import { Button } from "react-bootstrap";
import { Alert, Fade } from "@mui/material";


interface LanguageProps {
  languages: LanguagesEntity[];
  langState: LanguagesState;
  control: ControlTypes;

  // translation: TranslationType;
  onSourceTextUpdate?(targetId: number): void;

  onSourceSelect?(sourceLang: string): void;

  setAnimation?(animationClass: string): void;

  onNextStepClick?(activeStep: number): void;

  onTargetLanguage?(targetLang: string[]): void;

  getConstEstimation?(): void;

  onShowLanguagesPopUp?(show: boolean, content: string): void;
  fetchLanguages?(): void;

  // onSegmentsTranslate?(data: TranslationType): void,
}

const mapStateToProps = (state: RootState): LanguageProps => {

  return {
    languages: selectAllLanguages(state),
    langState: state.languages,
    control: getControlState(state),

    // translation: selectTranslationState(state),
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    onSourceSelect: (sourceLang: string) => dispatch(languagesActions.addSourceLang(sourceLang)),
    setAnimation: (animationClass: string) => dispatch(controlActions.setAnimationClass(animationClass)),
    onNextStepClick: (activeStep: number) => dispatch(generateSetAnimationOnNextStepAction({ activeStep })),
    onTargetLanguage: (targetLang: string[]) => dispatch(languagesActions.addTargetLanguage(targetLang)),
    getConstEstimation: () => dispatch(generateGetCostEstimation()),
    fetchLanguages: () => dispatch(generatorFetchLanguages()),
    onShowLanguagesPopUp: (show: boolean, content: string) =>
        dispatch(
            controlActions.showLanguagesPopUp({
              fileName: "",
              show: show,
              content: content,
            })
        ),
  };
};

const LanguagePicker: React.FC<LanguageProps> = (props: LanguageProps) => {
  useEffect(() => {
    props.fetchLanguages!();
    if (sessionStorage.getItem("lang") != null) {
      const preSetUpLanguageId = Number(sessionStorage.getItem("lang"));
      const preSetUpLanguage = props.languages.find((x) => x.id == preSetUpLanguageId);
      let lang: Array<string> = [preSetUpLanguage?.langCode!];
      props.onTargetLanguage!(lang);
      setBtnActive(false);
    }
    window.scrollTo(0, 0);
  }, []);

  //STATE
  // const [resetLanguageSelector, setResetLanguageSelector] = useState({
  //   systemName: "",
  // });
  const [btnActive, setBtnActive] = useState(true);

  const nextClickHandler = () => {
    props.onNextStepClick!(2);
    props.getConstEstimation?.();
  };
  const printDetectedLanguageName = () => {
    let lang = props.languages.find((x) => x.langCode.substring(0, 2) === props.langState.languageDetectedGeneralCode);
    if (lang === undefined) {
      return "unknown";
    } else if (lang.nativeName == "Undefined") {
      return "Select...";
    } else {
      return lang.nativeName;
    }
  };
  const printPreselectedTargetLang = (): LanguagesEntity | null => {
    if (sessionStorage.getItem("lang") != null) {
      const preSetUpLanguageId = Number(sessionStorage.getItem("lang"));
      return props.languages.find((x) => x.id == preSetUpLanguageId)!;
    }
    return null;
  };
  const sourceLangHandler = (option: SingleValue<LanguagesTypes>) => {
    if (props.langState.targetLanguages.some((x) => x == option!.langCode)) {
      props.onShowLanguagesPopUp!(true, "Target and source languages are the same");
      setBtnActive(true);
      return;
    }
    if (props.langState.targetLanguages.length == 0) {
      setBtnActive(true);
    } else {
      setBtnActive(false);
    }
    props.onSourceSelect!(option!.langCode);
    //setResetLanguageSelector({ systemName: "One" });
  };

  const targetLangHandler = (option: readonly LanguagesTypes[]) => {
    const lang: string[] = option.map((x) => x.langCode);

    if (option.some((x) => x.langCode.substring(0, 2) == props.langState.sourceLanguage.substring(0, 2))) {
      props.onShowLanguagesPopUp!(true, "Target and source languages are the same");
      setBtnActive(true);
      props.onTargetLanguage!(lang);
      return;
    }
    if(lang.length == 0){
      setBtnActive(true);
    }
    else {
      props.onTargetLanguage!(lang);
      props.langState.sourceLanguage.length == 0 ? setBtnActive(true) : setBtnActive(false);
    }
  };

  return (
      <div className={`card language-container animate__animated ${props.control.animation} `}>
        <div className="row language-header ps-4 pt-5">
          <div className="col">
            <div className="lang-text pt-2">
              <p>
                <span>Choose</span> languages
              </p>
            </div>
          </div>
        </div>

        <div className="lang-picker px-5">
          <div className="row xl:w-3/4 lg:w-3/4 sm:w-1/2">
            <div className="picker-1 pb-4">
              <Fade
                  in={props.control.showSameLanguagesPopUp}
                  style={{
                    transformOrigin: "0 0 0",
                    background: "red !important",
                  }}
                  {...(props.control.showSameLanguagesPopUp ? { timeout: 1000 } : { timeout: 1000 })}
              >
                <Alert severity="error" hidden={false} className={`info-popup`}>
                  {props.control.popUpTitle}
                </Alert>
              </Fade>
              <p>Translate from: </p>
              <Select placeholder={printDetectedLanguageName()} className="source" options={props.languages} getOptionLabel={(option) => option.systemName} onChange={sourceLangHandler} />
              {/* )} */}
            </div>
          </div>
          <div className="row xl:w-3/4 lg:w-3/4 sm:w-1/2">
            <div className="picker-2">
              <span style={{ paddingBottom: "1.2rem", display: "block" }}>Translate into:</span>
              <Select className="target" options={props.languages} defaultValue={printPreselectedTargetLang()} getOptionLabel={(option) => option.systemName} getOptionValue={(option) => option.langCode} onChange={targetLangHandler} isMulti />
            </div>
          </div>
        </div>
        <div className="row btn-next-container text-center pb-2">
          <div>
            <Button className="btn-next mx-auto mt-3" disabled={btnActive} onClick={() => nextClickHandler()}>
              Next
            </Button>
          </div>
        </div>
      </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguagePicker);
